import googleStoreImage from "../../assets/googleStoreImage.svg";
import appleStoreImage from "../../assets/appleStoreImage.svg";
import phoneImage from "../../assets/phoneImage.webp";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import "./index.css";

const DownloadComponent = () => {
  const gaPlayStoreTracker = useAnalyticsEventTracker("playstore button");
  const gaAppleStoreTracker = useAnalyticsEventTracker("appletore button");
  const storeData = [
    {
      title: "",
      alternate: "storeImage",
      source: googleStoreImage,
      link: "https://play.google.com/store/apps/details?id=com.gymgoer",
      tracker: gaPlayStoreTracker,
    },
    {
      title: "",
      alternate: "storeImage",
      source: appleStoreImage,
      link: "/appstore",
      tracker: gaAppleStoreTracker,
    },
  ];
  return (
    <div className="downloadComponentContent">
      <div className="downloadComponentLeft">
        <h2>Download </h2>
        <h2>the app</h2>
        <div className="downloadComponentStoreContainer">
          {storeData.map((item, index) => {
            return (
              <a
                href={item.link}
                key={index}
                onClick={() => item.tracker("store link")}
              >
                <img src={item.source} alt={item.alternate} />
              </a>
            );
          })}
        </div>
      </div>
      <div className="downloadComponentRight">
        <div className="downloadPageCircle">
          <img src={phoneImage} alt="phoneImage" />
        </div>
      </div>
    </div>
  );
};
export default DownloadComponent;
