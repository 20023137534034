import "./index.css";
import comingSoonGif from "../../assets/comingSoon.gif";

const ComingSoon = () => {
  return (
    <>
      <div className="comingSoonContainer">
        <img src={comingSoonGif} alt="comingSoonImage" />
      </div>
    </>
  );
};

export default ComingSoon;
