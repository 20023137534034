export const ALL_CATEGORIES = ['Nutrition', 'Workout', 'Fitness', 'Suppliments', 'Stories'];

export const BLOG_DATA = {
    Nutrition: {
        topArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/blogs/Nutrition/urlOne"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/blogs/Nutrition/url2"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/url4"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            // {
            //     date: "02 Sept 2023",
            //     title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
            //     subTitle: "By Rajat Saini",
            //     category: ["Nutrition", "Workout"],
            //     image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
            //     url: "/"
            // },
        ],
        moreArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        coverImage: "https://dailynotification.s3.ap-south-1.amazonaws.com/Rectangle+74.png"
    },
    Workout: {
        topArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        moreArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        coverImage: "https://dailynotification.s3.ap-south-1.amazonaws.com/Rectangle+74.png"
    },
    Fitness: {
        topArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            // {
            //     date: "02 Sept 2023",
            //     title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
            //     subTitle: "By Rajat Saini",
            //     category: ["Nutrition", "Workout"],
            //     image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
            //     url: "/"
            // },
        ],
        moreArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        coverImage: "https://dailynotification.s3.ap-south-1.amazonaws.com/Rectangle+74.png"
    },
    Suppliments: {
        topArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        moreArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        coverImage: "https://dailynotification.s3.ap-south-1.amazonaws.com/Rectangle+74.png"
    },
    Stories: {
        topArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        moreArticles: [
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
            {
                date: "02 Sept 2023",
                title: "ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...",
                subTitle: "By Rajat Saini",
                category: ["Nutrition", "Workout"],
                image: "https://dailynotification.s3.ap-south-1.amazonaws.com/testImg.png",
                url: "/"
            },
        ],
        coverImage: "https://dailynotification.s3.ap-south-1.amazonaws.com/Rectangle+74.png"
    },
}