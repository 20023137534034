import { useEffect, useRef, useState } from "react";
import "./index.css";
import proteinJar from "../../assets/Images/Store/proteinJar.png";
import customer1 from "../../assets/Images/Store/customer1.png";
import customer2 from "../../assets/Images/Store/customer2.png";
import customer3 from "../../assets/Images/Store/customer3.png";
import customer4 from "../../assets/Images/Store/customer4.png";
import customer5 from "../../assets/Images/Store/customer5.png";
import customer6 from "../../assets/Images/Store/customer6.png";
import customer7 from "../../assets/Images/Store/customer7.png";
import customer8 from "../../assets/Images/Store/customer8.png";
import mobileCustomer1 from "../../assets/Images/Store/mobileCustomer1.png";
import mobileCustomer2 from "../../assets/Images/Store/mobileCustomer2.png";
import mobileCustomer3 from "../../assets/Images/Store/mobileCustomer3.png";
import mobileCustomer4 from "../../assets/Images/Store/mobileCustomer4.png";
import videoFile from "../../assets/Images/Store/video.mp4";

const windowDimensions = window.innerWidth;

const Store = () => {
  const [animationState, setAnimationState] = useState("half-sphere");
  const [textAnimation, setTextAnimation] = useState("animate");
  const [paraAnimations, setParaAnimation] = useState("animation");
  const [btnAnimation, setBtnAnimation] = useState("orderBtn");
  const [jarAnimate, setJarAnimate] = useState("");
  const [jarTextAnimate, setJarTextAnimate] = useState("");

  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const handleAnimationEnd = () => {
    if (animationState === "half-sphere") {
      setAnimationState("BG-shade");
    }

    if (textAnimation === "animate") {
      setJarTextAnimate("show-text");
      setJarAnimate("show-jar");
    }
  };

  const jarAnimationEnded = () => {
    if (textAnimation === "animate") {
      setTextAnimation("text-state");
    }
  };

  const btnAnimationStart = () => {
    if (paraAnimations === "animation") {
      setParaAnimation("text-state");
    }

    if (btnAnimation === "orderBtn") {
      setBtnAnimation("btn-animate");
    }
  };

  return (
    <div style={{ paddingTop: 60, overflow: "hidden" }}>
      <div className="header-text">
        <h2
          className={`animate ${textAnimation}`}
          onAnimationEnd={btnAnimationStart}
        >
          Lets Build Fit India Together
        </h2>
        <p className={`animate ${paraAnimations}`}>
          Elevate Your Daily Protein Intake with GymGoer Whey Protein Where
          Premium Quality Meets Your Fitness Goals
        </p>
      </div>
      <button
        onAnimationEnd={btnAnimationStart}
        className={`orderBtn ${btnAnimation}`}
      >
        <h1>Order now</h1>
      </button>
      <div className="container">
        <div
          className={`jar-container ${jarAnimate}`}
          onAnimationEnd={jarAnimationEnded}
        >
          <img className="center-jar" src={proteinJar} />
        </div>
        <div className={`jar-detail-text ${jarTextAnimate}`}>
          <h1>25g</h1>
          <h3>Protien</h3>
        </div>
        <div className={`jar-drink-text ${jarTextAnimate}`}>
          <h3>Ready to drink in</h3>
          <h1>30s</h1>
        </div>
        <div
          className={`half-sphere ${animationState}`}
          onAnimationEnd={handleAnimationEnd}
        />
      </div>
      {/* Main Content Start */}
      <div className="banner-BG">
        <h1>
          <span className="start-quote">"</span>
          Craft Your Dream Physique with Every Sip
          <span className="end-quote">"</span>
        </h1>
      </div>
      <div className="review-BG">
        <div className="review-container">
          <div className="review">
            <h1>Pureity</h1>
            <p>
              GymGoer Whey protein defines the meaning of purity by following
              the zero adulteration policy. Every scoop of whey protein provides
              you the best in class experience with no additives. Our target is
              to improve the performance of the consumer in every aspect of
              achieving their goal towards the body building
            </p>
          </div>
          <div className="review">
            <h1>Flavour</h1>
            <p>
              The double chocolate flavour is truly best in class.It will takes
              your taste buds to a world of indulgence.Protein is often very
              dull without flavour, but chocolate flavour adds life to the
              drink, turning your drink into a blissful experience. Try the
              double chocolate flavour and share your delightful experience with
              us!
            </p>
          </div>
        </div>
        <div className="review-container" style={{ marginTop: "4rem" }}>
          <div className="review">
            <h1>Protein</h1>
            <p>
              With one serving per day fulfil your daily dose of protein intake.
              For the best results, mix one serving with water or milk and shake
              for 60-90 seconds and have this high protein shake post workout or
              consume it as anytime snack in your balanced diet.A serving of 34
              g scoop gives you 24g of Protein.
            </p>
          </div>
          <div className="review">
            <h1>Digestibility & Absorption</h1>
            <p>
              The rapid digestion and absorption makes it an ideal choice for
              your post-workout recovery and provides you best in class muscle
              building results. We recommend 1 scoop of Whey which can serve you
              25 grams of Protein post workout for best results. No sugar added
              in the product.For more information read the Nutrition information
              section on the package
            </p>
          </div>
        </div>
      </div>
      <div style={{ position: "relative", zIndex: -1, top: "-2px" }}>
        <svg viewBox="0 0 500 100">
          <path
            d="M 0 60 C 150 150 300 0 500 80 L 500 0 L 0 0"
            fill="#512C00"
          ></path>
        </svg>
      </div>
      <div className="protein-container">
        <div className="shade-overlay" />
        <img
          src={require("../../assets/Images/Store/protienImageBG.png")}
          alt="backgroundImage"
          className="protein-background"
        />
        <div className="supplement-header">
          <h1>Flavoured with Chocolate</h1>
        </div>
        <div className="supplement">
          <div>
            <h1>25g</h1>
            <p>Protein</p>
          </div>
          <div>
            <h1>11.7g</h1>
            <p>EAAs*</p>
          </div>
        </div>
        <div className="supplement-type">
          <div>
            <h1>5.5g</h1>
            <p>BCAAs*</p>
          </div>
          <div>
            <h1>4.3g</h1>
            <p>Glutamic Acid</p>
          </div>
        </div>
      </div>

      <div className="usage-BG">
        {windowDimensions > 480 ? (
          <h2>
            How to use <span style={{ color: "#BB68E7" }}>GG Fitness</span> Whey
            Protein?
          </h2>
        ) : (
          <h2>How to use?</h2>
        )}
        <div className="consumtion-container">
          <div className="consumtion-box">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.317"
                height="40.304"
                viewBox="0 0 75.317 75.304"
              >
                <path
                  id="FontAwsome_utensil-spoon_"
                  data-name="FontAwsome (utensil-spoon)"
                  d="M70.6,4.679C62.51-3.426,46.345-.4,37.093,8.871c-7.252,7.252-8.1,16.18-4.236,23.594L1.305,60.765a4,4,0,0,0-.147,5.81L8.7,74.121a4.01,4.01,0,0,0,5.81-.162l28.3-31.537c7.413,3.869,16.342,3.015,23.594-4.236,9.267-9.252,12.3-25.418,4.192-33.508Z"
                  transform="translate(0.022 0.015)"
                  fill="#663722"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.272"
                height="40.696"
                viewBox="0 0 52.272 69.696"
              >
                <path
                  id="FontAwsome_prescription-bottle_"
                  data-name="FontAwsome (prescription-bottle)"
                  d="M4.356,26.136H20.691a1.092,1.092,0,0,1,1.089,1.089V29.4a1.092,1.092,0,0,1-1.089,1.089H4.356V39.2H20.691a1.092,1.092,0,0,1,1.089,1.089v2.178a1.092,1.092,0,0,1-1.089,1.089H4.356v8.712H20.691a1.092,1.092,0,0,1,1.089,1.089v2.178a1.092,1.092,0,0,1-1.089,1.089H4.356V65.34A4.369,4.369,0,0,0,8.712,69.7H43.56a4.369,4.369,0,0,0,4.356-4.356V17.424H4.356ZM49.005,0H3.267A3.277,3.277,0,0,0,0,3.267V9.8a3.277,3.277,0,0,0,3.267,3.267H49.005A3.277,3.277,0,0,0,52.272,9.8V3.267A3.277,3.277,0,0,0,49.005,0Z"
                  fill="#cc8d54"
                />
              </svg>
            </div>
            <h3>
              Start consuming ½ Scoop (17.5 gl of GYMGOER® Whey Protein in 100mL
              of chilled water for the initial 3 days in a row.
            </h3>
          </div>
          <div className="consumtion-box">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.317"
                height="40.304"
                viewBox="0 0 75.317 75.304"
              >
                <path
                  id="FontAwsome_utensil-spoon_"
                  data-name="FontAwsome (utensil-spoon)"
                  d="M70.6,4.679C62.51-3.426,46.345-.4,37.093,8.871c-7.252,7.252-8.1,16.18-4.236,23.594L1.305,60.765a4,4,0,0,0-.147,5.81L8.7,74.121a4.01,4.01,0,0,0,5.81-.162l28.3-31.537c7.413,3.869,16.342,3.015,23.594-4.236,9.267-9.252,12.3-25.418,4.192-33.508Z"
                  transform="translate(0.022 0.015)"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.272"
                height="40.696"
                viewBox="0 0 52.272 69.696"
              >
                <path
                  id="FontAwsome_prescription-bottle_"
                  data-name="FontAwsome (prescription-bottle)"
                  d="M4.356,26.136H20.691a1.092,1.092,0,0,1,1.089,1.089V29.4a1.092,1.092,0,0,1-1.089,1.089H4.356V39.2H20.691a1.092,1.092,0,0,1,1.089,1.089v2.178a1.092,1.092,0,0,1-1.089,1.089H4.356v8.712H20.691a1.092,1.092,0,0,1,1.089,1.089v2.178a1.092,1.092,0,0,1-1.089,1.089H4.356V65.34A4.369,4.369,0,0,0,8.712,69.7H43.56a4.369,4.369,0,0,0,4.356-4.356V17.424H4.356ZM49.005,0H3.267A3.277,3.277,0,0,0,0,3.267V9.8a3.277,3.277,0,0,0,3.267,3.267H49.005A3.277,3.277,0,0,0,52.272,9.8V3.267A3.277,3.277,0,0,0,49.005,0Z"
                />
              </svg>
            </div>
            <h3>
              From 4th day, consume 1 Heaping Scoop (35g) of GYMGOER® Whey
              Protein in 200mL of chilled water.
            </h3>
          </div>
        </div>
        <div className="video-container">
          <video ref={videoRef} className="video-box" autoPlay controls muted>
            <source src={videoFile} type="video/mp4" />
          </video>
        </div>
        <div className="customer-detail">
          <h1 className="customer-title">Happy & Fit Customers</h1>
          {windowDimensions > 480 ? (
            <div className="customer-container">
              <div className="customer-image-container">
                <div>
                  <img src={customer1} className="customer-img" />
                  <img src={customer2} className="customer-img" />
                </div>
                <div>
                  <img src={customer3} className="customer-img" />
                  <img src={customer4} className="customer-img" />
                </div>
              </div>
              <div className="customer-count">
                <h1>100000+ Fit Customers</h1>
              </div>
              <div className="customer-image-container">
                <div>
                  <img src={customer5} className="customer-img" />
                  <img src={customer6} className="customer-img" />
                </div>
                <div>
                  <img src={customer7} className="customer-img" />
                  <img src={customer8} className="customer-img" />
                </div>
              </div>
            </div>
          ) : (
            <div className="mobile-image-container">
              <div>
                <img src={mobileCustomer1} className="mobile-customer-img" />
                <img src={mobileCustomer2} className="mobile-customer-img" />
              </div>
              <div className="mobile-customer-count">
                <h1>100000+ Customers</h1>
              </div>
              <div>
                <img src={mobileCustomer3} className="mobile-customer-img" />
                <img src={mobileCustomer4} className="mobile-customer-img" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Store;
