import gymgoerLogo from "../../../assets/Images/Store/gymGoerLogo.png";
import appleStoreImage from "../../../assets/Images/Store/appStore.png";
import googleStoreImage from "../../../assets/Images/Store/playStore.png";
import facebookIcon from "../../../assets/Images/Store/facebookLogo.png";
import instagramIcon from "../../../assets/Images/Store/instagramLogo.png";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import { NavLink } from "react-router-dom";

const NewFooter = () => {
    const otherPage = [
        { title: "Home", path: "#" },
        { title: "About Us", path: "#" },
        // { title: "Blog", path: "#" },
        { title: "Store", path: "#" },
        { title: "Partner With Us", path: "#" },
    ];

    const gaPlayStoreTracker = useAnalyticsEventTracker("playstore button");
    const gaAppleStoreTracker = useAnalyticsEventTracker("appletore button");
    const storeData = [
        {
            title: "",
            alternate: "storeImage",
            source: appleStoreImage,
            link: "https://apps.apple.com/in/app/gymgoer/id6451078977",
            tracker: gaAppleStoreTracker,
        },
        {
            title: "",
            alternate: "storeImage",
            source: googleStoreImage,
            link: "https://play.google.com/store/apps/details?id=com.gymgoer",
            tracker: gaPlayStoreTracker,
        },
    ];

    const socialMediaData = [
        {
            title: "Facebook",
            icon: facebookIcon,
            alternate: "socialIcon",
            link: "https://www.facebook.com/profile.php?id=100088500812604",
        },
        {
            title: "Instagram",
            icon: instagramIcon,
            alternate: "socialIcon",
            link: "https://www.instagram.com/gymgoer.fit/",
        },
        // {
        //     title: "LinkedIn",
        //     icon: linkedinIcon,
        //     alternate: "socialIcon",
        //     link: "https://www.linkedin.com/company/gymgoer/?viewAsMember=true",
        // },
    ];

    return (
        <div className="footer-store-container">
            <div className="footerLogoContainer">
                <NavLink to="/">
                    <img src={gymgoerLogo} alt="logo" />
                </NavLink>
            </div>
            <div className="footerOption">
                <ul>
                    {otherPage.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.path}>{item.title}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="app-download">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {socialMediaData.map((data, index) => (
                        <a href={data.link} key={index}>
                            <img
                                src={data.icon}
                                className="social-logo"
                                alt={data.alternate}
                            />
                        </a>
                    ))}
                </div>
                <h3 className="discover-app-text">Discover our app</h3>
                <div style={{ display: "flex", gap: 8 }}>
                    {storeData.map((item, index) => (
                        <a
                            href={item.link}
                            key={index}
                            onClick={() => item.tracker("store link")}
                        >
                            <img
                                src={item.source}
                                className="download-logo"
                                alt={item.alternate}
                            />
                        </a>
                    ))}
                </div>
            </div>
            <div className="reserve-right-container">
                <p className="reserve-right-text">
                    All rights reserved@fitclubgymgoer.fit
                </p>
            </div>
        </div>
    );
};

export default NewFooter;
