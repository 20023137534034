import React from 'react';

const BlogsBreadCrums = ({ title, category }) => {
    return (
        <div className='breadCrumsContainer'>
            <span>Blogs > </span>
            <span>{category} > </span>
            <span>{title}</span>
        </div>
    )
}

export default BlogsBreadCrums;
