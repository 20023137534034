import { useEffect, useState } from "react";
import "./index.css";

const AboutUs = () => {
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="about-us-page">
      <div className="about-content-container">
        <h1>About us</h1>
        <p className="first-paragraph">
          Welcome to GymGoer, your trusted fitness partner on the path to a
          healthier, stronger you. We understand that your fitness journey is
          unique, and that's why we've created GymGoer - a platform designed
          with your fitness goals in mind.
        </p>
        <p className="other-paragraph">
          At GymGoer, we're passionate about making fitness accessible to
          everyone. Whether you're a seasoned fitness enthusiast or just
          starting out, we believe that everyone should have the opportunity to
          lead a healthier lifestyle. That's why we've developed a platform that
          connects you with the best fitness facilities and programs at
          affordable prices.
        </p>
      </div>
      <div className="about-setup">
        <div className="wave-container">
          {windowDimensions > 480 ? (
            <svg
              className="wave-svg-BG"
              viewBox="0 0 1510 1073"
              fill="none"
              preserveAspectRatio="xMidYMid slice"
              width="100%" // Set width to 100%
              height="100%"
            >
              <path
                d="M654 31.5C410.278 31.5001 0 5.34058e-05 0 5.34058e-05V1047C0 1047 238.594 1067.32 391.878 1071.5C663.555 1078.9 816.456 1025.56 1087.37 1047C1252.98 1060.1 1508 1071.5 1508 1071.5V0C1508 0 986.5 31.4999 654 31.5Z"
                fill="#E9D3F5"
              />
            </svg>
          ) : (
            <svg
              className="wave-svg-BG"
              xmlns="http://www.w3.org/2000/svg"
              width="393"
              height="1054"
              viewBox="0 0 393 1054"
              fill="none"
            >
              <path
                d="M170.439 31.2895C106.923 31.2896 0 5.30489e-05 0 5.30489e-05V1040C0 1040 63.5526 1048.55 103.5 1052.7C174.302 1060.04 219.119 1033.66 302 1040C346.849 1043.43 393 1052.7 393 1052.7V0C393 0 257.092 31.2894 170.439 31.2895Z"
                fill="#EDD6F9"
              />
            </svg>
          )}
          <div className="wave-content">
            <h1 style={{ paddingTop: '20px' }}>What sets us apart</h1>

            <div>
              <h2>Affordable Fitness</h2>
              <p>
                We believe that fitness should be within reach for all. With
                GymGoer, you can access top-notch gyms and fitness centers for
                as low as INR 99/- per visit, making your fitness journey not
                only effective but also budget-friendly.
              </p>
            </div>
            <div>
              <h2>Diverse Options</h2>
              <p>
                We offer a wide range of fitness options, from traditional gyms
                to specialized studios and wellness centers. Whether you prefer
                weightlifting, yoga, cardio, or a combination of activities,
                GymGoer has something for everyone.
              </p>
            </div>
            <div>
              <h2>Convenience</h2>
              <p>
                Our user-friendly platform makes it easy to find and book
                fitness sessions that fit your schedule. No more hassle - just a
                seamless fitness experience.
              </p>
            </div>
            <div>
              <h2>Community</h2>
              <p>
                Join a thriving fitness community that supports and motivates
                you along your journey. Share your progress, connect with fellow
                fitness enthusiasts, and celebrate each other's successes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-content-container">
        <h1>Our Vision</h1>
        <p className="first-paragraph">
          GymGoer is on a mission to transform the health and fitness landscape
          in India, targeting the young and dynamic population. We are committed
          to making a significant difference in the lifestyles of Indians by
          promoting a fitness-oriented way of living.
        </p>
        <p className="other-paragraph">
          Currently, only 50 Lakhs (0.3%) of the Indian population is actively
          engaged in strength training, while a staggering 49.9% of Americans
          visit the gym at least twice a week. This stark contrast underscores
          the urgent need for Indians to prioritize fitness and well-being.
        </p>
        <p className="other-paragraph">
          At GymGoer, our focus is clear: to enhance the lives of young Indians
          by providing top-tier, yet affordable gym memberships. What sets us
          apart is our innovative 'Pay Per Visit' model, offering flexibility
          and cost-effectiveness like never before.
        </p>
        <p className="other-paragraph">
          Join us in our mission to empower Young India to lead healthier, more
          active lives with GymGoer
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
