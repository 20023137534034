import React, { useRef, useState } from "react";

const FaqCard = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const answerRef = useRef(null);
  const textRef = useRef(null);

  const toggleView = () => {
    if (answerRef.current.className.includes("show")) {
      setIsActive(!isActive);
      answerRef.current.style.height = "0px";
      answerRef.current.style.opacity = 0;
    } else {
      setIsActive(!isActive);
      answerRef.current.style.height = textRef.current.offsetHeight + "px";
      answerRef.current.style.opacity = 1;
    }
  };
  return (
    <div className="questionItem">
      <div className="questionOuter">
        <div className="questionContainer">
          <div className="numbering">{item.number}</div>
        </div>

        <div>
          <div className="questionHeader">
            <h2>{item.question}</h2>
            <button
              className={isActive ? "expandButton active" : "expandButton"}
              onClick={() => {
                toggleView();
              }}
            >
              <span
                className={
                  isActive ? "plusHorizontal active" : "plusHorizontal"
                }
              ></span>
              <span className={isActive ? "plus active" : "plus"}></span>
            </button>
          </div>

          <div
            id="answerContent"
            className={isActive ? "show" : ""}
            ref={answerRef}
          >
            <p ref={textRef}>{item.answer}</p>
          </div>
        </div>
      </div>

      <div className={isActive ? "fadedBorder2" : "fadedBorder1"}></div>
    </div>
  );
};

export default FaqCard;
