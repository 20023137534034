import React from "react";
import "./index.css";
import appleIcon from "../../../assets/appleSvg.svg";
import googlePlayIcon from "../../../assets/googlePlaySvg.svg";
import screenCenter from "../../../assets/phoneScreenCenter.png";
import screenLeft from "../../../assets/phoneScreenLeft.png";
import screenRight from "../../../assets/phoneScreenRight.png";
import cancelIcon from "../../../assets/cancelIcon.svg";

const QrMobileModal = () => {
  const playStoreData = [
    {
      icon: googlePlayIcon,
      alter: "icon",
      link: "https://play.google.com/store/apps/details?id=com.gymgoer",
    },
    {
      icon: appleIcon,
      alter: "icon",
      link: "https://apps.apple.com/in/app/gymgoer/id6451078977",
    },
  ];
  const phoneScreenData = [
    { icon: screenCenter, name: "centerScreen" },
    { icon: screenLeft, name: "leftScreen" },
    { icon: screenRight, name: "rightScreen" },
  ];
  return (
    <div className="qrMobileContainer">
      <div className="cancelContainer">
        <img src={cancelIcon} alt="icon" />
      </div>
      <h1>Download The App</h1>
      <div className="storeImageContainer">
        {playStoreData.map((item) => {
          return (
            <a href={item.link} target="blank">
              <img src={item.icon} alt={item.alter} />
            </a>
          );
        })}
      </div>
      <div className="qrMobileContent">
        <div className="phoneScreenContainer">
          {phoneScreenData.map((item) => {
            return <img src={item.icon} className={item.name} />;
          })}
        </div>
      </div>
      <div className="backgroundSphere"></div>
    </div>
  );
};

export default QrMobileModal;
