import React from "react";
import "./index.css";
import FaqCard from "./FaqCard";

const faqData = [
  {
    number: "01",
    question: "What is GymGoer?",
    answer:
      "GymGoer is built to make the workout routine more easy and fast.At present,India we have 70% of the population above 18 years of age and not everyone out there has the factor of affordability towards the fitness.Is fitness very expensive? That's where the idea of GymGoer came into existence. We started using technology to solve this issue. You can find the nearby gyms and without paying high membership one can opt for Pay Per Visit concept.Check out our app for more details.",
  },
  {
    number: "02",
    question: "What kind of Gyms are available at GymGoer?",
    answer:
      "GymGoer is partnering with 100+ gyms at the moment.We have active partners in Vijayawada (50+) and in Hyderabad (50+).Gyms starting at just @ ₹99 are present in the GymGoer App. Gyms fully equipped with all kinds of muscle activation and muscle building are available on our app. Gyms with Squat Power Rack,Barbells,Weight Bench,Preacher Curl Bench,Leg Press Machine,Calf Machine,Lat PullDown/Low Row,Kettlebells,EZ Curl Bar,Smith Machine,Leg Curl/Leg Extension Machine,Dumbbells,Foldable Wall Rack with Pull Up Bar,Curve Treadmill,Elliptical Machine,Spin Bike,Dip Bar etc are present in the partnered gyms.",
  },
  {
    number: "03",
    question: "What kind of Services does GymGoer offers?",
    answer:
      "GymGoer offers unique concept of Pay Per Visit, Gym Monthly subscriptions, attendance tracker, and also provides rewards for the workouts you do.Interestingly you can opt the fitness products in the platform like Whey Protein, mass gainer, multi vitamins, cod liver oil etc from the App itself by using your reward points.",
  },
  {
    number: "04",
    question: "Can I pay now and use it later?",
    answer:
      "Yes, You can choose your slot from any available time from the GymGoer App and can workout accordingly. You can also select any gym near by you and partnered with GymGoer one on each day and can enjoy the workout session.",
  },
  {
    number: "05",
    question: "What are the facilities offered at Gym?",
    answer:
      "The facilities and amenities offered at Gym are clearly mentioned in the App. You can choose the Gym with facilities like locker, shower, shoe racks, washrooms etc. GymGoer checks and approves the facilities at each gym while onboarding. Hence you can trust us and enjoy your bookings.",
  },
  {
    number: "06",
    question: "What safety precautions will be taken at the center?",
    answer:
      "Every Gym in the App is approved by the GymGoer team on standard neatness and also evey center is customer centric.They will provide you complete freedom to use all equipments.We suggest our customers to make sure on the kind of equipment and the weights to be double sure. The GymGoer team can't be responsible for the acts of heavy lifting and improper training.",
  },
  {
    number: "07",
    question: "How to know the location of a Gym?",
    answer:
      "We started with our first partner Gym from Vijayawada, Andhra Pradesh. Now we have 100+ Gyms across 2 cities of Hyderabad and Vijaywada.You can open the GymGoer App on both Android and iOS and check the Gyms near you. You can even choose to change the location of the city to check out the Gyms present in these cities to plan your office trips or personal trips. We know your point of not missing the Gym Session even on the tight travel schedule.",
  },
  {
    number: "08",
    question: "How to get Gym Contact details?",
    answer:
      "You can open the GymGoer App and go to my profile. The mobile number under 'Help&Support' section will get all your queries done. Feel free to call us at any time. We are always available for customers (24*7). Incase you can't find the solution for your problem from our support center , feel free to let us know about your concern at Support@gymgoer.fit",
  },
  {
    number: "09",
    question: "What is the duration of each slot?",
    answer:
      "Booking a slot at any Partnered Gym you can workout for 1Hour 30 Minutes or 90 Minutes. During this period of 90 minutes you can utilise the complete amenities and also you can use every machine which helps you build your dream body.",
  },
  {
    number: "10",
    question: "What do I need to carry to the Gym for my workout?",
    answer:
      "Workout equipment like weights, boxing bags, machines are available at every Gym. We also have hand sanitisers available at the center for your safety.facilities like locker, shower, shoe racks, washrooms etc. are also available at Majority of the partnered Gyms. But we suggest the customer get their own water bottle, Hand Towel, Workout shoes etc. GymGoer will not be responsible for the customer items. Customers should be aware of surroundings and take care of their belongings.",
  },
  {
    number: "11",
    question: "What time should I reach the Gym?",
    answer:
      "Reachout to the Gym before 5 minutes of your slot. Scan the QR code at the entrance or reception and enjoy the workout session. Don't worry even if you are late by 10-15 minutes of booking time. Our partners are super friendly to understand your situation and will cooperate with the customer. But make sure the date of booking matches while you enter the Gym.In case of any issues kindly call our support centre from 'Help&Support' section under my profile.",
  },
  {
    number: "12",
    question: "What modes of payment are accepted?",
    answer:
      "We accept all major credit and debit cards. We also accept payments by Internet Banking, UPI and wallets.Check out the payment options during the payment confirmation screen and opt for your easy way.",
  },
];

const FaqSection = () => {
  return (
    <div className="faqContainer">
      <h1 className="faqHeader">Frequently asked Questions</h1>
      <div className="faqContent">
        {faqData.map((item) => {
          return <FaqCard item={item} />;
        })}
      </div>
    </div>
  );
};

export default FaqSection;
