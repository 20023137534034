import React from 'react';
import BlogHeader from './subheader/BlogHeader.jsx';

const Blogs = () => {
    return (
        <div>
            <BlogHeader />
        </div>
    );
}

export default Blogs;
