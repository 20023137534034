import React from "react";
import TextAnimation from "../TextAnimation";
import "./index.css";
import giftSvg from "../../../../assets/giftIcon.svg";
import fireSvg from "../../../../assets/fireIcon.svg";
import clockSvg from "../../../../assets/clockIcon.svg";
import visitSvg from "../../../../assets/visitIcon.svg";
import appDemoPng from "../../../../assets/appDemoImage.png";
import discountSvg from "../../../../assets/discountIcon.svg";

const Perks = () => {
  const perkData = [
    {
      heading: "Any Time Any gym Anywhere",
      icon: clockSvg,
      subHeading: "Workout at any gym, at any time with GymGoer App.",
    },
    {
      heading: "Affordable Fitness Commitments",
      icon: fireSvg,
      subHeading: "No need to pay on expensive memberships.",
    },
    {
      heading: "Your hard work will get rewarded",
      icon: giftSvg,
      subHeading: "Earn rewards on every workout session.",
    },
    {
      heading: "Make payments at the gym on visit basis",
      icon: visitSvg,
      subHeading: "Visit your near by gym just @ ₹99/-",
    },
  ];
  const ticketData = [
    {
      heading: "Affordable",
      icon: discountSvg,
      positioning: { top: 60, left: -50, transform: "rotate(15.84deg)" },
      name: "ticketLeft",
    },
    {
      heading: "Convenient",
      icon: discountSvg,
      positioning: { bottom: 80, right: -40, transform: "rotate(-12.84deg)" },
      name: "ticketBottom",
    },
    {
      heading: "Discounted Price",
      icon: discountSvg,
      positioning: { top: 60, right: -70, transform: "rotate(15.84deg)" },
      name: "ticketRight",
    },
    {
      heading: "Discounted Price",
      icon: discountSvg,
      positioning: { bottom: 30, left: -60, transform: "rotate(-12.84deg)" },
      name: "ticketBottomLeft",
    },
  ];
  return (
    <div className="mainWrapper">
      <TextAnimation />
      <div className="perkContainer">
        <div className="perkContent">
          <h1>Benefits with GymGoer</h1>
          <div className="perkItems">
            {perkData.map((item) => {
              return (
                <div className="perkCard">
                  <div className="perkIconContainer">
                    <img src={item.icon} alt="icon" />
                  </div>
                  <div className="perkTextContainer">
                    <h3>{item.heading}</h3>
                    <h4>{item.subHeading}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="appDemoContainer">
          {ticketData.map((item) => {
            return (
              <div
                className={`discountTicket ${item.name}`}
                style={{ ...item.positioning }}
              >
                <img
                  src={item.icon}
                  alt="svg"
                  style={{
                    width: "3.3rem",
                    height: "3.3rem",
                    marginTop: "-0.2rem",
                  }}
                />
                <h4>{item.heading}</h4>
              </div>
            );
          })}

          <img src={appDemoPng} alt="phoneImage" />
        </div>
      </div>
    </div>
  );
};

export default Perks;
