import React from "react";
import "./index.css";
import tickIcon from "../../../../assets/rightTickIcon.svg";

const PerksTable = () => {
  const tableData = {
    headings: ["", "With GymGoer", "Without GymGoer"],
    rows: [
      ["Pay Per Visit", tickIcon, "-"],
      ["Upto 25% off on memberships", tickIcon, "-"],
      ["24hrs Support", tickIcon, "-"],
      ["Daily Rewards", tickIcon, "-"],
      ["Attendance Tracker", tickIcon, "-"],
    ],
  };
  return (
    <div className="perksTableContainer">
      <h2>What Difference Does GymGoer</h2>
      <h2>Make In Your Fitness Journey</h2>
      <div className="tableSection">
        <table>
          <thead>
            <tr>
              {tableData.headings.map((data) => {
                return <th>{data}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((item) => {
              return (
                <tr>
                  <td className="criteriaName">{item[0]}</td>
                  <td style={{ textAlign: "center" }}>
                    <img src={item[1]} />
                  </td>
                  <td style={{ textAlign: "center" }}>{item[2]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PerksTable;
