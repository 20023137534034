import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import "./styles.scss";
import TopArticle from '../subheader/TopArticle';
import { ALL_CATEGORIES, BLOG_DATA } from '../constant';
import BlogsBreadCrums from './BlogsBreadCrums';

const ArticleFull = () => {
    let params = useParams();
    const navigate = useNavigate();
    console.log('secondd', params);

    const [selectedCategory, setSelectedCategory] = useState(params?.category || 'Nutrition');
    const [categoryData, setCategoryData] = useState(BLOG_DATA[params?.category || 'Nutrition']);

    const goToAnArticle = (articleURL) => {
        navigate(articleURL);
    }

    const changeCategory = (newCategory) => {
        navigate("/blogs/" + newCategory);
    }

    return (
        <div >
            <div className='blogHeaderContainer'>
                {
                    ALL_CATEGORIES.map((category) => {
                        return (
                            <ul
                                style={{ color: category === selectedCategory ? '#3B005A' : 'white' }}
                                onClick={() => changeCategory(category)}>{category}
                            </ul>
                        )
                    })
                }
            </div>
            <div className='articleContent'>
                <BlogsBreadCrums category={params?.category || 'Nutrition'} title='ACE and the Academy of Nutrition...' />
                <div className='articleHeader'>
                    <h3>02 Sept 2023</h3>
                    <h1>ACE and the Academy of Nutrition and Dietetics Join Forces ACE and the Academy of Nutrition...</h1>
                    <h4>by Rajat Saini</h4>
                </div>
                <div className='articleBody'>
                    <p>
                        Starting tomorrow, September 15, customers can pre-order Apple’s incredible new iPhone 15 and iPhone 15 Pro models and choose from a number of financing, delivery, and pickup options offered only in Apple Retail.
                    </p>
                    <p>
                        Apple Watch Series 9, Apple Watch Ultra 2, and AirPods Pro (2nd generation) with MagSafe Charging (USB-C) — the perfect companions to iPhone — are already available to order. Along with the iPhone 15 lineup, these new products will be available beginning Friday, September 22.</p>
                    <br />

                    <img src={require('../testImg.jpg')} alt='food with a lot of screen fruits' />
                    <p>Starting tomorrow, September 15, customers can pre-order Apple’s incredible new iPhone 15 and iPhone 15 Pro models and choose from a number of financing, delivery, and pickup options offered only in Apple Retail.</p>
                    <img src={require('../testImg.jpg')} alt='food with a lot of screen fruits' />
                    <p>Starting tomorrow, September 15, customers can pre-order Apple’s incredible new iPhone 15 and iPhone 15 Pro models and choose from a number of financing, delivery, and pickup options offered only in Apple Retail.</p>
                </div>
                <div className='articleFooter'>
                    <h3 className='blogSubheading'>More On This Topic</h3>
                    <div className='moreArticleWrapper'>
                        {categoryData.moreArticles.map((article, index) => (<TopArticle key={index} articleInfo={article} singleLiner={true} goToAnArticle={goToAnArticle} />))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleFull;
