import CountUp from "react-countup";

const Statistics = () => {
  const numberOfGyms = [
    { title: "Cities", count: 2 },
    { title: "Gyms", count: 112 },
    { title: "Goers", count: 3200 },
    { title: "Gos", count: 9800 },
  ];
  return (
    <div className="statisticsContainer" id="aboutus">
      {numberOfGyms.map((item, index) => {
        return (
          <div className="counterContainer" key={index}>
            <CountUp
              start={0}
              end={item.count}
              duration={3}
              className="counter"
              formattingFn={(value) => (value < 10 ? `0${value}` : value)}
            ></CountUp>
            <h3>{item.title}</h3>
          </div>
        );
      })}
    </div>
  );
};
export default Statistics;
