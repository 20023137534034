import Navbar from "../NavbarComponent";
import { Outlet } from "react-router-dom";
import Footer from "../HomePage/components/newFooter.jsx";
import "./index.css";
import { useState, useRef, createContext } from "react";
import QrModal from "../Modals/QrModal/QrModal.jsx";
import QrMobileModal from "../Modals/QrMobileModal/index.jsx";

export const modalContext = createContext();
const windowDimension = window.innerWidth;

const Layout = () => {
  const [showModal, setShowModal] = useState(false);
  const [animateModal, setAnimateModal] = useState(true);
  const modalRef = useRef();
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <modalContext.Provider
      value={{ toggleModal, setAnimateModal, animateModal }}
    >
      <div>
        {showModal && (
          <div className="fadeLayer" onClick={toggleModal} ref={modalRef}>
            {windowDimension > 768 ? <QrModal /> : <QrMobileModal />}
          </div>
        )}
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </modalContext.Provider>
  );
};

export default Layout;
