import React, { useState, useContext } from "react";
import "./index.css";
import sampleGymImage from "../../../../assets/sampleGymImage.png";
import navIcon from "../../../../assets/navigationIcon.svg";
import playButton from "../../../../assets/playIcon.svg";
import { modalContext } from "../../../LayoutPage";

const GymList = () => {
  const { toggleModal, setAnimateModal } = useContext(modalContext);
  const [selectedCity, setSelectedCity] = useState(0);
  const onboardedCityData = [
    {
      heading: "Hyderabad",
      list: [
        { name: "Intense fitness", location: "Gachibowli", price: 149, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/bf83f675-41cc-4924-ab45-8d12acb287dc-Intense fitness (1).jpeg" },
        { name: "Curl Fit Gym", location: "Banjara Hills", price: 199, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/6e27abec-333a-40f7-9a12-d83ec8effbd6-Curl Fit Gym (1).jpeg" },
        { name: "Power Zone Gym", location: "Mehedipatnam", price: 199, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/ba7dfc9c-60c1-4a8d-8881-8a775611a134-Power Zone Gym (2).jpeg" },
      ],
      index: 0,
      name: "leftCity",
    },
    {
      heading: "Vijayawada", list: [
        { name: "Gravite Fitness", location: "Bhavanipuram", price: 99, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/8b0289c4-2aea-43da-ae9b-e5e233af6e3a-1.jpg" },
        { name: "B-Fit Gym", location: "Bhavanipuram", price: 99, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/663692b2-f6c0-4f6b-804a-32a0feb9c699-1.jpeg" },
        { name: "Lifelong Fitness", location: "Poranki", price: 99, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/34424c13-6d36-4619-b8d9-618fddca3413-3.jpg" },
      ], index: 1, name: "middleCity"
    },
    {
      heading: "Bangalore", list: [
        { name: "Eclipz Fitness Studio", location: "Electronic city", price: 199, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/fe4e825b-fd3e-48b2-a641-9c10675160c9-847300720-e-clipz-fitness-studio.jpg" },
        { name: "Liger Fitness", location: "Maruthinagar", price: 199, imgUrl: "https://rs-highon.s3.ap-south-1.amazonaws.com/gymsImages/6e6f6419-866b-4107-88e4-8797acdeba96-1img-20220216-190604-vaishak-p.jpg" },
      ], index: 2, name: "rightCity"
    },
  ];
  const selectedCityStyle = {
    background:
      "linear-gradient(0deg, rgba(255, 175, 101, 0.51) 0%, rgba(217, 217, 217, 0.00) 100%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    border: "1px solid linear-gradient(to top #FFFFFF 100%, #000000 0%)",
    borderRadius: "12px",
    padding: "0.5rem 1rem",
  };

  return (
    <div className="gymListContainer">
      <div className="gymCityContainer">
        <div
          className="playButton"
          onClick={() => {
            if (selectedCity > 0) {
              setSelectedCity((old) => old - 1);
            }
          }}
        >
          <img src={playButton} alt="icon" />
        </div>
        <div className="centerCityMode">
          <h2>{onboardedCityData[selectedCity].heading}</h2>
        </div>
        {onboardedCityData.map((item, index) => {
          return (
            <div
              style={selectedCity === index ? selectedCityStyle : null}
              className={item.name}
            >
              <h2
                onClick={() => {
                  setSelectedCity(index);
                }}
              >
                {item.heading}
              </h2>
            </div>
          );
        })}
        <div
          className="playButton rightNavigateButton"
          onClick={() => {
            if (selectedCity < 2) {
              setSelectedCity((old) => old + 1);
            }
          }}
        >
          <img src={playButton} alt="icon" />
        </div>
      </div>

      <h2 className="topGymText">Top Gyms</h2>
      <div className="selectedCityContainer">
        {onboardedCityData[selectedCity].list.map((item, index) => {
          if (index <= 2) {
            return (
              <div className="gymCard" onClick={() => {
                toggleModal();
                setAnimateModal(false);
              }}>
                <img src={item.imgUrl || sampleGymImage} className="sampleImage" />
                <div className="infoContainer">
                  <div className="infoLeft">
                    <h2>{item.name}</h2>
                    <h3>INR {item.price}/-</h3>
                  </div>
                  <div className="infoRight">
                    <div className="navigateInfo">
                      <img src={navIcon} alt="icon" />
                      <h2>{item.location}</h2>
                    </div>
                    <h3>Know more</h3>
                  </div>
                </div>
              </div>
            );
          }
        })}
        <div className="moreText">
          <p
            onClick={() => {
              toggleModal();
              setAnimateModal(false);
            }}
          >
            Find more gyms
          </p>
        </div>
      </div>
    </div>
  );
};

export default GymList;
