// import Header from "./components/header";
// import Benefits from "./components/benefits";
// import Footer from "./components/footer";
import Statistics from "./components/statistics";
// import Download from "./components/download";
// import Features from "./components/features";
import "./components/style.css";
import SaleCarousel from "./components/SaleCarousel";
import Perks from "./components/BenefitSection";
import GymList from "./components/GymListSection";
import PerksTable from "./components/PerksTable";
import ProductSale from "./components/ProductAd";
import CommunitySection from "./components/CommunitySection";
// import ArticleSection from "./components/ArticleSection";
import FaqSection from "./components/FaqSection";

const HomePage = () => {
  return (
    <div>
      <SaleCarousel />
      <Statistics />
      <Perks />
      <GymList />
      <PerksTable />
      <ProductSale />
      <CommunitySection />
      {/* <ArticleSection /> */}
      <FaqSection />
      {/* <Header /> */}
      {/* <Benefits />
      <Features />
      <Download />
      <Footer /> */}
    </div>
  );
};

export default HomePage;
