import React from 'react';

const TopArticle = ({ articleInfo, singleLiner, goToAnArticle, topSingleLiner }) => {
    const { date, title, image, subTitle, category, url } = articleInfo;

    return (
        <div className={`topArticleContainer ${singleLiner ? 'singleLiner' : ''} ${topSingleLiner ? 'topSingleLiner' : ''}`}  
            onClick={() => goToAnArticle(url)}>
            <div className={`articleCoverPage ${singleLiner ? 'singleLiner' : ''} ${topSingleLiner ? 'topSingleLiner' : ''}`}>
                <img src={image} alt={title} />
            </div>
            <div className='articleContentInfo'>
                <h6>{date}</h6>
                <h2>
                    {title}
                </h2>
                {topSingleLiner && <p>{subTitle}</p>}
                <h6 className='categoryName'>{category[0]}</h6>
            </div>
            {singleLiner ? 
                <img className='redirectionLineIcon' src={require('./arrowImg.png')} alt='more information' />
                : 
                <img className='redirectionIcon' src={require('./arrowImg.png')} alt='more information' />
            }
        </div>
    )
}

export default TopArticle;
