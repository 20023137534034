import "./index.css";
import gymgoerLogo from "../../assets/gymgoerLogo.png";
import { useRef, useState } from "react";

const OwnerShare = () => {
  const lineRef = useRef();
  const [plan, setPlan] = useState(12);
  const [yearlyFee, setYearlyFee] = useState("");
  const [perMonthValue, setPerMonthValue] = useState("0");
  const [perDayValue, setPerDayValue] = useState("0");
  const [totalFee, setTotalFee] = useState("0");
  const [userPay, setUserPay] = useState("0");
  const [ownerFee, setOwnerFee] = useState("0");

  const breakdownData = [
    {
      title: "Per month Value",
      value: perMonthValue,
    },
    {
      title: "Per visit Value",
      value: perDayValue,
    },
    {
      title: "Gymgoer share",
      value: 5,
    },
  ];

  const formResetter = () => {
    setYearlyFee("");
    setPerDayValue(0);
    setOwnerFee(0);
    setPerMonthValue(0);
    setTotalFee(0);
    setUserPay(0);
  };

  const handleKeyDown = (event) => {
    // Allow only numbers and the Backspace/Delete keys
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }

    // Limit input length to 6
    if (
      yearlyFee.length >= 6 &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const shiftLine = (value) => {
    if (value === "12") {
      lineRef.current.className = "animatedLine";
      setPlan(12);
      calculatePerDayOwner(yearlyFee, 12);
    } else {
      lineRef.current.className = "animatedLine right";
      setPlan(6);
      calculatePerDayOwner(yearlyFee, 6);
    }
    formResetter();
  };

  const calculatePerDayOwner = (fee, tenture) => {
    if (Number(fee) >= 1000) {
      const temp = (fee / tenture).toFixed(1);
      const temp2 = (temp / 30).toFixed(1);
      setPerMonthValue(temp);
      setPerDayValue(temp2);
      setTotalFee(temp2 - 0 + 5);
      const condition = Math.ceil(temp2 - 0 + 5);
      let dummyProfit = 0;
      if (condition <= 39) {
        setUserPay(39);
        dummyProfit = 39;
      } else if (condition <= 49) {
        setUserPay(49);
        dummyProfit = 49;
      } else if (condition <= 59) {
        setUserPay(59);
        dummyProfit = 59;
      } else if (condition <= 69) {
        setUserPay(69);
        dummyProfit = 69;
      } else if (condition <= 79) {
        setUserPay(79);
        dummyProfit = 79;
      } else if (condition <= 89) {
        setUserPay(89);
        dummyProfit = 89;
      } else if (condition <= 99) {
        setUserPay(99);
        dummyProfit = 99;
      } else if (condition <= 109) {
        setUserPay(109);
        dummyProfit = 109;
      } else if (condition <= 119) {
        setUserPay(119);
        dummyProfit = 119;
      } else if (condition <= 129) {
        setUserPay(129);
        dummyProfit = 129;
      } else if (condition <= 139) {
        setUserPay(139);
        dummyProfit = 139;
      } else if (condition <= 149) {
        setUserPay(149);
        dummyProfit = 149;
      } else if (condition <= 159) {
        setUserPay(159);
        dummyProfit = 159;
      } else if (condition <= 169) {
        setUserPay(169);
        dummyProfit = 169;
      } else if (condition <= 179) {
        setUserPay(179);
        dummyProfit = 179;
      } else if (condition <= 189) {
        setUserPay(189);
        dummyProfit = 189;
      } else if (condition <= 199) {
        setUserPay(199);
        dummyProfit = 199;
      } else if (condition <= 209) {
        setUserPay(209);
        dummyProfit = 209;
      } else if (condition <= 219) {
        setUserPay(219);
        dummyProfit = 219;
      } else if (condition <= 229) {
        setUserPay(229);
        dummyProfit = 229;
      } else if (condition <= 239) {
        setUserPay(239);
        dummyProfit = 239;
      } else if (condition <= 249) {
        setUserPay(249);
        dummyProfit = 249;
      } else if (condition <= 259) {
        setUserPay(259);
        dummyProfit = 259;
      } else if (condition <= 269) {
        setUserPay(269);
        dummyProfit = 269;
      } else if (condition <= 279) {
        setUserPay(279);
        dummyProfit = 279;
      } else if (condition <= 289) {
        setUserPay(289);
        dummyProfit = 289;
      } else if (condition <= 299) {
        setUserPay(299);
        dummyProfit = 299;
      } else {
        setUserPay(condition);
        dummyProfit = condition;
      }
      setOwnerFee(dummyProfit - 5);
    } else {
      setPerDayValue(0);
      setOwnerFee(0);
      setPerMonthValue(0);
      setTotalFee(0);
      setUserPay(0);
    }
  };

  return (
    <>
      <div className="shareContainer">
        <div className="header">
          <div className="ownerLogo">
            <img src={gymgoerLogo} alt="navLogo" />
          </div>
        </div>
        <div className="monthPlanContainer">
          <div className="monthPlan">
            <h3
              onClick={() => {
                shiftLine("12");
              }}
            >
              12 months
            </h3>
            <h3
              onClick={() => {
                shiftLine("6");
              }}
            >
              6 months
            </h3>
          </div>
          <div className="planUnderline">
            <div className="animatedLine" ref={lineRef}></div>
          </div>
          <div className="shareFieldContainer">
            <div className="yearlyContainer">
              <h3>Enter plan</h3>
              <div className="yearlyInput">
                <h3>₹</h3>
                <input
                  type="number"
                  value={yearlyFee}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setYearlyFee(e.target.value);
                      calculatePerDayOwner(e.target.value, plan);
                    }
                  }}
                  onKeyDown={(event) => {
                    handleKeyDown(event);
                  }}
                  placeholder="00,000"
                />
              </div>
            </div>
            {breakdownData.map((item, index) => {
              return (
                <div key={index} className="breakdownContainer">
                  <h3>{item.title}</h3>
                  <h3>₹ {item.value}</h3>
                </div>
              );
            })}
            <div className="totalContainer">
              <h3>Total</h3>
              <h3>₹ {totalFee}</h3>
            </div>
            <div className="userPaymentContainer">
              <div className="userPerDay">
                <h3>User payment</h3>
                <p>(per visit)</p>
                <span>Rounded @ ₹39, 49, 59, 69, 79, 89.</span>
              </div>
              <h3>₹ {userPay}</h3>
            </div>
            <div className="ownerFeeContainer">
              <h3>Gym owner share</h3>
              <p>(per visit)</p>
              <h2>₹ {ownerFee}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OwnerShare;
