import React from "react";
import "./index.css";
import communityImage from "../../../../assets/communityImage.png";
import instagramIcon from "../../../../assets/instaSvg.svg";
import fbIcon from "../../../../assets/FbSvg.svg";

const CommunitySection = () => {
  const mediaData = [
    {
      icon: instagramIcon,
      alter: "icon",
      link: "https://www.instagram.com/gymgoer.fit/",
    },
    {
      icon: fbIcon,
      alter: "icon",
      link: "https://www.facebook.com/profile.php?id=100088500812604",
    },
  ];
  return (
    <div
      className="communityContainer"
      style={{ backgroundImage: `url(${communityImage})` }}
    >
      <div className="maskLayer">
        <div className="gradientText">Join the Biggest Fitness Community</div>
        <h3>
          5000+ GymGoers’ are inspiring us everyday towards the journey of
          fitness.
        </h3>
        <div className="mediaContainer">
          {mediaData.map((item) => {
            return (
              <a target="blank" href={item.link}>
                <img src={item.icon} alt={item.alter} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CommunitySection;
