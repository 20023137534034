import googleStoreImage from "../../assets/googleStoreImage.svg";
import appleStoreImage from "../../assets/appleStoreImage.svg";
import phoneImage from "../../assets/phoneImage.webp";
import gymgoerLogo from "../../assets/gymgoerLogo.png";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import "./index.css";

const GymDownload = () => {
  const gaPlayStoreTracker = useAnalyticsEventTracker("playstore button");
  const gaAppleStoreTracker = useAnalyticsEventTracker("appletore button");
  const storeData = [
    {
      title: "",
      alternate: "storeImage",
      source: googleStoreImage,
      link: "https://play.google.com/store/apps/details?id=com.GymGoers_owner",
      tracker: gaPlayStoreTracker,
    },
    {
      title: "",
      alternate: "storeImage",
      source: appleStoreImage,
      link: "/appstore",
      tracker: gaAppleStoreTracker,
    },
  ];
  return (
    <div className="downloadPageContainer">
      <div className="downloadPageLogoContainer">
        <img src={gymgoerLogo} alt="logo" />
      </div>
      <div className="downloadPageContent">
        <div className="downloadPageLeft">
          <h1>Partner With Us</h1>
          <h2>Download </h2>
          <h2>GymGoer Partner app</h2>
          <div className="downloadPageStoreContainer">
            {storeData.map((item, index) => {
              return (
                <a
                  href={item.link}
                  key={index}
                  onClick={() => item.tracker("store link")}
                >
                  <img src={item.source} alt={item.alternate} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="downloadPageRight">
          <div className="downloadPageCircle">
            <img src={phoneImage} alt="phoneImage" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GymDownload;
