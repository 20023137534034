import React, { useState } from "react";
import "./index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import appleIcon from "../../../../assets/appleSvg.svg";
import googlePlayIcon from "../../../../assets/googlePlaySvg.svg";
import decorImage from "../../../../assets/decoreIcon.svg";
import adBgImage from "../../../../assets/adBGImage.png";

const SaleCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const storeData = [
    {
      alternate: "storeImage",
      source: appleIcon,
      link: "https://apps.apple.com/in/app/gymgoer/id6451078977",
    },
    {
      alternate: "storeImage",
      source: googlePlayIcon,
      link: "https://play.google.com/store/apps/details?id=com.gymgoer",
    },
  ];
  const slides = [
    <div key={1}>
      <div className="carouselContainer">
        <div className="adContent">
          <h2>Find Best Gyms Around you</h2>
          <div className="priceContainer">
            <h1>Lowest price</h1>
            <h1>ever just at</h1>
            <h1>
              <div className="decorContainer">
                <h1>₹ 99/-</h1>
                <img src={decorImage} alt="image" />
              </div>
              per visit
            </h1>
          </div>
          <p className="descriptionLine">
            We have more that 120+ Gyms in GymGoer that allows you to choose
            from. What are you waiting for check them out now
          </p>
          <div className="appStoreContainer">
            {storeData.map((item) => {
              return (
                <a target="blank" href={item.link}>
                  <img src={item.source} alt={item.alternate} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="slideContainer">
          <img src={adBgImage} className="slideImage" alt="Slide 1" />
        </div>
      </div>
    </div>,
    <div key={2}>
      <div className="carouselContainer">
        <div className="adContent">
          <h2>Find Best Gyms Around you</h2>
          <div className="priceContainer">
            <h1>Lowest price</h1>
            <h1>ever just at</h1>
            <h1>
              <div className="decorContainer">
                <h1>₹ 99/-</h1>
                <img src={decorImage} alt="image" />
              </div>
              per visit
            </h1>
          </div>
          <p className="descriptionLine">
            We have more that 120+ Gyms in GymGoer that allows you to choose
            from. What are you waiting for check them out now
          </p>
          <div className="appStoreContainer">
            {storeData.map((item) => {
              return (
                <a target="blank" href={item.link}>
                  <img src={item.source} alt={item.alternate} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="slideContainer">
          <img src={adBgImage} className="slideImage" alt="Slide 1" />
        </div>
      </div>
    </div>,
  ];

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: 10,
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i, active) => (
      <div
        style={{
          width: "9px",
          height: "9px",
          backgroundColor: i === currentSlide ? "white" : "black",
          opacity: i === currentSlide ? 1 : 0.5,
          borderRadius: "50%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {active}
      </div> // Adjust the dot size and styling here
    ),
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <Slider {...settings} afterChange={(index) => setCurrentSlide(index)}>
        {slides}
      </Slider>
    </div>
  );
};
export default SaleCarousel;
