import React, { useEffect, useState, useRef } from "react";
import "./index.css";

const TextAnimation = () => {
  const traverseRef = useRef();
  const [currentWord, setCurrentWord] = useState(0);
  const animationText = [
    "Pay Per Visit",
    "Near by gyms",
    "Rewards",
    "Affordable prices",
  ];

  useEffect(() => {
    const intervalId = setInterval(changeWord, 4000);
    return () => clearInterval(intervalId);
  }, [currentWord]);
  function changeWord() {
    setCurrentWord((prevWord) => (prevWord >= 3 ? 0 : prevWord + 1));
    const element = traverseRef.current;
    if (element) {
      const index = currentWord * 3.8;
      if (index > 0) {
        element.style.transition = "transform 0.5s ease";
        element.style.transform = `translateY(-${index}rem)`;
      } else {
        element.style.transition = "transform 0s ease";
        element.style.transform = `translateY(-${index}rem)`;
      }
    }
  }
  return (
    <div className="animationContainer">
      <div className="doubleWrapper">
        <p>A platform that gives you</p>
        <div className="animationWrapper">
          <div ref={traverseRef} className="animatedList">
            {animationText.map((item) => {
              return <div className="animatedText">{item}</div>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAnimation;
