import "./index.css";
import BGImage from "../../assets/Images/PartnerWithUs/BGImage.png";
import scanCircleIcon from "../../assets/Images/PartnerWithUs/scanCircle.png";
import cardIcon from "../../assets/Images/PartnerWithUs/card.png";
import personIcon from "../../assets/Images/PartnerWithUs/person.png";
import checkboxIcon from "../../assets/Images/PartnerWithUs/checkbox.png";
import pieIcon from "../../assets/Images/PartnerWithUs/pieIcon.png";
import graphIcon from "../../assets/Images/PartnerWithUs/graphIcon.png";
import monitorIcon from "../../assets/Images/PartnerWithUs/monitorIcon.png";
import calenderIcon from "../../assets/Images/PartnerWithUs/calenderIcon.png";
import phoneLeft from "../../assets/Images/PartnerWithUs/phoneLeft.png";
import phoneCenter from "../../assets/Images/PartnerWithUs/phoneCenter.png";
import phoneRight from "../../assets/Images/PartnerWithUs/phoneRight.png";
import playStoreIcon from "../../assets/Images/PartnerWithUs/playStoreIcon.png";
import appleIcon from "../../assets/Images/PartnerWithUs/appleIcon.png";
import { useState } from "react";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";

const windowDimensions = window.innerWidth;

const PartnerWithUs = () => {
  const [hovered, setHovered] = useState(null);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const handleMouseEnter = (index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const gaPlayStoreTracker = useAnalyticsEventTracker("playstore button");
  const gaAppleStoreTracker = useAnalyticsEventTracker("appletore button");
  const storeData = [
    {
      title: "",
      alternate: "storeImage",
      source: playStoreIcon,
      link: "https://play.google.com/store/apps/details?id=com.gymgoer",
      tracker: gaPlayStoreTracker,
    },
    {
      title: "",
      alternate: "storeImage",
      source: appleIcon,
      link: "https://apps.apple.com/in/app/gymgoer/id6451078977",
      tracker: gaAppleStoreTracker,
    },
  ];

  const handleCloseModal = () => {
    setOpenFormModal(false);
  };

  const handleOpenModal = () => {
    if (windowDimensions > 620) {
      setOpenFormModal(true);
    } else if (windowDimensions > 480) {
      window.scrollTo({ top: 2750, behavior: "smooth" });
    } else if (windowDimensions < 400) {
      window.scrollTo({ top: 2500, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 3000, behavior: "smooth" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrorMsg("");

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { firstName, lastName, email, phoneNumber } = formData;

    const namePattern = /^[A-Za-z]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[0-9]*$/;

    if (!firstName) {
      setErrorMsg("First name is required");
      return;
    }

    if (!namePattern.test(firstName)) {
      setErrorMsg("First name can only contain letters");
      return;
    }

    if (!lastName) {
      setErrorMsg("Last name is required");
      return;
    }

    if (!namePattern.test(lastName)) {
      setErrorMsg("Last name can only contain letters");
      return;
    }
    if (!email) {
      setErrorMsg("Email is required");
      return;
    }
    if (!emailPattern.test(email)) {
      setErrorMsg("Invalid email");
      return;
    }
    if (!phoneNumber) {
      setErrorMsg("Phone Number is required");
      return;
    }
    if (
      !phonePattern.test(phoneNumber) ||
      phoneNumber.length < 10 ||
      phoneNumber.length > 10
    ) {
      setErrorMsg("Invalid Phone Number");
      return;
    }

    try {
      const response = await fetch(
        "https://gymgoer-staging-9etv.onrender.com/api/v1/partnerWithUs/userAffiliateForm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) console.log("Form data submitted successfully");
      else console.error("Failed to submit form data");

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="partner-with-us-page">
      {openFormModal ? (
        <div className="modal-container">
          <form className="form-modal" onSubmit={handleSubmit}>
            <h1>Join with us</h1>
            <div onClick={handleCloseModal} className="close-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 80 80"
                fill="none"
              >
                <path
                  d="M40.002 7.49848C22.0816 7.49848 7.50195 22.0782 7.50195 39.9985C7.50195 57.9188 22.0816 72.4985 40.002 72.4985C57.9223 72.4985 72.502 57.9188 72.502 39.9985C72.502 22.0782 57.9223 7.49848 40.002 7.49848ZM53.5379 49.9985L50.002 53.5344L40.002 43.5344L30.002 53.5344L26.466 49.9985L36.466 39.9985L26.466 29.9985L30.002 26.4625L40.002 36.4625L50.002 26.4625L53.5379 29.9985L43.5379 39.9985L53.5379 49.9985Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="form-name">
              <input
                onChange={handleChange}
                value={formData.firstName}
                type="text"
                name="firstName"
                placeholder="First Name"
                maxLength={60}
              />
              <input
                onChange={handleChange}
                value={formData.lastName}
                type="text"
                name="lastName"
                placeholder="Last Name"
                maxLength={60}
              />
            </div>
            <input
              onChange={handleChange}
              value={formData.email}
              type="email"
              name="email"
              placeholder="Mail ID"
              className="modal-contact-detail"
              maxLength={60}
            />
            <div>
              <input
                onChange={handleChange}
                value={formData.phoneNumber}
                type="number"
                name="phoneNumber"
                placeholder="Phone Number"
                className="modal-contact-detail"
              />
            </div>
            {errorMsg ? (
              <p style={{ color: "red", marginTop: 10 }}>{errorMsg}</p>
            ) : null}
            <div
              style={{ marginTop: errorMsg ? 20 : 40 }}
              className="button-container"
            >
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      ) : null}
      <div className="bg-container">
        <img src={BGImage} />
        <div className="over-img-text">
          <h1>Partner With Us</h1>
          <p>Start the fitness revolution today!</p>
          <button onClick={handleOpenModal}>Join us today</button>
        </div>
      </div>
      <div className="banner">
        <div>
          <p>Make the difference! Help the fitness community</p>
        </div>
        <div className="store-icon-container">
          {storeData.map((item, index) => (
            <a
              href={item.link}
              key={index}
              onClick={() => item.tracker("store link")}
            >
              <img
                src={item.source}
                className="download-logo"
                alt={item.alternate}
              />
            </a>
          ))}
        </div>
      </div>
      <div className="partner-container">
        <h1>How does it work?</h1>
        <div className="partner-detail">
          <div className="partner-detail-gap">
            <div className="partner-detail-box">
              <img src={personIcon} />
              <h4>Join us and provide your basic details</h4>
            </div>
            <div className="partner-detail-box">
              <img src={cardIcon} />
              <h4>Provide payment and bank details</h4>
            </div>
          </div>
          <div className="partner-detail-gap">
            <div className="partner-detail-box">
              <img src={checkboxIcon} />
              <h4>Complete the whole onboarding process</h4>
            </div>
            <div className="partner-detail-box">
              <img src={scanCircleIcon} />
              <h4>Go Live and provide services to the users</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-container">
        <h1>Benefits with GymGoer</h1>
        <div className="benefits-detail">
          <div className="benefits-detail-gap">
            <div
              onMouseEnter={() => handleMouseEnter(0)}
              onMouseLeave={handleMouseLeave}
              className={`benefits-detail-box ${hovered === 0 && windowDimensions > 620 ? "hovered" : ""
                }`}
            >
              <img src={pieIcon} />
              <h3>Data Oriented Publicity</h3>
              {windowDimensions > 620 ? (
                <>
                  {hovered === 0 && (
                    <p style={{ marginTop: 9, fontSize: 14 }}>
                      Content related to feature
                    </p>
                  )}
                </>
              ) : (
                <p>
                  target is to improve the performance of the consumer in every
                  aspect of achieving their goal towards the body building
                </p>
              )}
            </div>
            <div
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
              className={`benefits-detail-box ${hovered === 1 && windowDimensions > 620 ? "hovered" : ""
                }`}
            >
              <img src={monitorIcon} />
              <h3>Transparent Pricing System</h3>
              {windowDimensions > 620 ? (
                <>
                  {hovered === 1 && (
                    <p style={{ marginTop: 9, fontSize: 14 }}>
                      Content related to feature
                    </p>
                  )}
                </>
              ) : (
                <p>
                  target is to improve the performance of the consumer in every
                  aspect of achieving their goal towards the body building
                </p>
              )}
            </div>
          </div>
          <div
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
            className="benefits-detail-gap"
          >
            <div
              className={`benefits-detail-box ${hovered === 2 && windowDimensions > 620 ? "hovered" : ""
                }`}
            >
              <img src={graphIcon} />
              <h3>Digital Marketing</h3>
              {windowDimensions > 620 ? (
                <>
                  {hovered === 2 && (
                    <p style={{ marginTop: 9, fontSize: 14 }}>
                      Content related to feature
                    </p>
                  )}
                </>
              ) : (
                <p>
                  target is to improve the performance of the consumer in every
                  aspect of achieving their goal towards the body building
                </p>
              )}
            </div>
            <div
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
              className={`benefits-detail-box ${hovered === 3 && windowDimensions > 620 ? "hovered" : ""
                }`}
            >
              <img src={calenderIcon} />
              <h3>Weekly Payout</h3>
              {windowDimensions > 620 ? (
                <>
                  {hovered === 3 && (
                    <p style={{ marginTop: 9, fontSize: 14 }}>
                      Content related to feature
                    </p>
                  )}
                </>
              ) : (
                <p>
                  target is to improve the performance of the consumer in every
                  aspect of achieving their goal towards the body building
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="svg-container">
        <svg
          style={{ position: "absolute" }}
          width="1512"
          height="2033"
          viewBox="0 0 1512 2033"
          fill="none"
        >
          <path
            d="M-33 35.4991C-33 35.4991 6.00035 82.9981 251.5 35.499C497 -12.0001 507 -9.50197 756 35.499C1005 80.5 913 70 1134 35.499C1355 0.9981 1512 76.5 1512 76.5V2032.5H0L-33 35.4991Z"
            fill="#BB68E7"
            fillOpacity="0.27"
          />
        </svg>
        <div className="partner-form-container" id="partner-form">
          <div className="form-container">
            <h1>Join with us</h1>
            <form onSubmit={handleSubmit} className="form-details">
              <div className="detail-name">
                <input
                  onChange={handleChange}
                  value={formData.firstName}
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  maxLength={60}
                />
                <input
                  onChange={handleChange}
                  value={formData.lastName}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  maxLength={60}
                />
              </div>
              <input
                onChange={handleChange}
                value={formData.email}
                name="email"
                type="email"
                placeholder="Mail ID"
                className="contact-detail"
                maxLength={60}
              />
              <input
                onChange={handleChange}
                value={formData.phoneNumber}
                name="phoneNumber"
                type="number"
                placeholder="Phone Number"
                className="contact-detail"
              />
              <div className="button-container">
                <button>Submit</button>
              </div>
            </form>
          </div>
          <div className="img-container">
            <div className="phone-img-container">
              <img src={phoneLeft} className="phone-side-img" />
              <img src={phoneCenter} className="phone-center-img" />
              <img src={phoneRight} className="phone-side-img" />
            </div>
            <div className="overlay-phone" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
