import React from "react";
import "./index.css";
import productImage from "../../../../assets/productImage.png";

const ProductSale = () => {
  return (
    <div className="productOuterContainer">
      <div className="productContainer">
        <div className="saleInfo">
          <h4>Introductory offer</h4>
          <h3>Flat 50% off</h3>
          <p>Find more about the revolutionary GG Fitness Whey Protein</p>
          <div className="exploreButton"  >Explore more</div>
        </div>
        <div className="productView">
          <img src={productImage} alt="product image" />
        </div>
      </div>
    </div>
  );
};

export default ProductSale;
